<template>
    <component :is="template">
        <v-responsive class="member-points py-9 px-6 px-md-3">
            <v-layout justify-center><span class="title">포인트내역</span></v-layout>
            <v-divider class="mt-6"/>
            <v-data-table :items="points" :headers="pointsHeaders" disable-pagination disable-sort hide-default-footer>
                <span slot="item.amount" slot-scope="{item}">{{ item.amount.format() }} 원</span>
                <span slot="item.remain" slot-scope="{item}">{{ item.remain.format() }} 원</span>
                <span slot="item.createdAt" slot-scope="{item}">{{ $dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss") }}</span>
            </v-data-table>
            <v-divider />
            <v-pagination v-model="page" :length="pageCount" total-visible="11" @input="search(true)" class="mt-6" />
        </v-responsive>
    </component>
</template>

<script>
import api from "@/api";
export default {
    data(){
        return {

            filter: {},

            page: 1,
            pageCount: 0,
            limit: 10,

            points: [],
            pointsHeaders: [
                { text: "금액", value: "amount", align: "center" },
                { text: "잔액", value: "remain", align: "center" },
                { text: "비고", value: "remark", align: "center" },
                { text: "날짜", value: "createdAt", align: "center" },
            ]
        }
    },
    mounted(){
        if(this.logoff) this.$router.push(`/login`);
        this.init();
    },
    methods: {
        async init(){
            await this.search(false);
        },
        async search(routable = false){
            if(routable){
                this.$router.push({
                    query: { ...this.filter, page: this.page }
                });
            }

            let { summary, points } = await api.v1.me.points.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit,
                },
                params: this.filter
            });

            this.pageCount = Math.ceil(summary.totalCount / this.limit);
            this.points = points;
        }
    },
    computed: {
        template(){ return () => import(`@/templates/${this.$theme.pages.mypage}`) },
        
        logoff(){
            return !this.$store.state.accessToken;
        }
    }
}
</script>

<style scoped>
.member-points .title {
    font-size: 22px !important;
    font-weight: 600;
}
.member-points >>> .v-data-table__mobile-row {
    height: auto;
}
</style>